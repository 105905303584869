<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="page-title-box">
          <h4 class="page-title">Superior/Hr Assessment Result</h4>
        </div>
      </div>
    </div>
    <!-- end page title -->
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <place-holder v-if="loading"></place-holder>
            <div class="table-responsive" v-if="!loading">
              <table
                class="table table-striped dt-responsive w-100 mb-3"
                id="datatable"
                v-if="!loading"
              >
                <thead v-if="!loading">
                  <tr>
                    <th>No.</th>
                    <th>Module</th>
                    <th>Employee Name</th>
                    <th>Trainning Date</th>
                    <th>Assessor Name</th>
                    <th>State</th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody v-if="!loading">
                  <tr v-for="(data, index) in assessment_result" :key="index">
                    <td>{{ ++index }}</td>
                    <td>
                      {{
                        data.assessment_before_one_weeks.monthly_plan.module
                          .name
                      }}
                    </td>
                    <td>
                      {{ data.assessment_before_one_weeks.enrolled_user.name }}
                    </td>
                    <td>
                      {{ data.assessment_before_one_weeks.training_date }}
                    </td>
                    <td>
                      {{ data.assessment_before_one_weeks.assessor.name }}
                    </td>
                    <td>
                      {{ data.state }}
                    </td>
                    <td>{{ checkStatus(data.status) }}</td>
                    <td>
                      <!-- <span v-if="data.status"> -->
                      <router-link
                        class="action-icon"
                        :to="{
                          name: 'assessment-result/edit',
                          params: { id: data.id },
                        }"
                      >
                        <i class="mdi mdi-square-edit-outline"></i>
                      </router-link>
                      <!-- </span> -->
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//Datatable Program Timeline
import "datatables.net/js/jquery.dataTables.min.js";

import "datatables.net-bs5/css/dataTables.bootstrap5.min.css";
import "datatables.net-bs5/js/dataTables.bootstrap5.min.js";
import "datatables.net-responsive-bs5/css/responsive.bootstrap5.min.css";
import "datatables.net-responsive-bs5/js/responsive.bootstrap5.min.js";
import $ from "jquery";
import axios from "axios";
import { useToast } from "vue-toastification";
import badgeSuccess from "../../../components/shared/badgeSuccess.vue";
import badgeDanger from "../../../components/shared/badgeDanger.vue";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
//Mixins
import userHasPermissions from "../../../mixins/userHasPermissions";

export default {
  components: {
    badgeSuccess,
    badgeDanger,
    vSelect,
  },
  mixins: [userHasPermissions],
  setup() {
    const toast = useToast();
    return { toast };
  },
  data() {
    return {
      assessment_result: [],
      loading: false,
      baseUrl: process.env.VUE_APP_BASE_URL,
    };
  },
  methods: {
    checkStatus(status) {
      if (status == 0) {
        return "Waiting";
      } else if (status == 1) {
        return "Confirmed";
      } else if (status == 2) {
        return "Drafted";
      }
    },
    async getAssessmentResult() {
      this.loading = true;
      this.$Progress.start();
      await axios
        .get(`${this.baseUrl}admin/v2/after-assessment-result`)
        .then((response) => {
          // console.log('respone=>',response.data.data);
          this.assessment_result = response.data.data;
          this.loading = false;
          this.$Progress.finish();
        })
        .catch((error) => {
          this.errors = error.response.data.errors;
          this.toast.error("Something Went Wrong!");
        });
      $("#datatable").DataTable();
    },
    clearFilter() {
      $.fn.dataTable.ext.search.pop();
      $("#datatable").DataTable().draw();
    },
  },
  created() {
    this.clearFilter();
    this.getAssessmentResult();
  },
};
</script>

<style>
.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #4a81d4;
  border-color: #4a81d4;
}
</style>
